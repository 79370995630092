<template>
  <b-col
    md="12"
    lg="6"
    cols="12"
  >
    <b-card
      id="performence-revenue-order"
      no-body
    >
      <b-overlay
        :show="loading"
        class="lg:h-[39rem] md:h-[45rem] xl:h-[45rem]"
        spinner-variant="primary"
        variant="light"
        blur="0"
        opacity="0.3"
      >
        <b-card-body style="padding: 14px !important">
          <div class="d-flex p-1">
            <div class="d-flex">
              <span class="text-2xl my-auto max-w-[300px] font-semibold">
                Performa Omset & Orderan</span>
              <img
                id="infoPerformence"
                class="ml-[5px] my-auto"
                src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
              >
              <b-popover
                triggers="hover"
                target="infoPerformence"
                placement="bottom"
              >ini adalah performa omset dan orderan (customer yang beli) bulan ini</b-popover>
            </div>
            <div class="ml-auto mr-[8px] w-[100px] p-[5px] bg-[#F4F4F4] rounded-lg my-auto">
              <b-img src="https://storage.googleapis.com/komerce/assets/svg/komship-logo-yellow.svg" />
            </div>
            <b-button
              id="shareChart"
              variant="none"
              class="p-0 my-auto"
              size="sm"
            >
              <b-img
                class="w-[20px]"
                src="@/assets/images/icons/icon-telegram.svg"
                @click="shareRevenuePerformenceOrder()"
              />
            </b-button>
            <b-popover
              target="shareChart"
              triggers="hover"
              placement="bottomleft"
            >
              Share statistik order ini</b-popover>
          </div>
          <b-row class="justify-between">
            <b-col
              sm="6"
              cols="10"
              class="bg-warning text-white py-1 rounded-r-lg"
            >
              <b-row>
                <b-col
                  cols="5"
                  style="border-right: 1px dashed white"
                >
                  <p class="text-lg mb-0">
                    Total Omset</p>
                  <p class="text-xl font-semibold mb-0">
                    {{ formatRupiah(totalProfit) }}</p>
                </b-col>
                <b-col cols="5">
                  <p class="text-lg mb-0">
                    Total Orderan</p>
                  <p class="text-xl font-semibold mb-0">
                    {{ totalOrder }}</p>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              class="d-flex align-items-end"
            >
              <flat-pickr
                v-model="filterMonth"
                class="custom-select"
                :config="flatpickrMonthly"
                @input="getRevenuePerformenceOrder()"
              />
              <b-form-select
                v-model="filterPerfomance"
                class="h-[2.71rem] custom-select mx-1"
                :options="filterPerfomanceOptions"
                @change="getRevenuePerformenceOrder()"
              />
            </b-col>
          </b-row>
          <VueApexcharts
            ref="myChart"
            width="100%"
            type="area"
            :options="options"
            :series="seriesRevenue"
          />
        </b-card-body>
      </b-overlay>
    </b-card>
  </b-col>
</template>
<script>
import VueApexcharts from 'vue-apexcharts'
import html2canvas from 'html2canvas'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import '@/@core/scss/vue/libs/vue-flatpicker.scss'
import MonthSelect from 'flatpickr/dist/plugins/monthSelect'
import flatPickr from 'vue-flatpickr-component'
import { Indonesian } from 'flatpickr/dist/l10n/id'
import {
  today,
} from '@/store/helpers'
import { START_DATE_OF_MONTH, END_DATE_OF_MONTH } from '@/libs/formatDate'

export default {
  components: { VueApexcharts, flatPickr },
  data() {
    return {
      totalProfit: 0,
      totalOrder: 0,
      loading: false,
      START_DATE_OF_MONTH,
      END_DATE_OF_MONTH,
      seriesRevenue: [],
      filterMonth: today,
      flatpickrMonthly: {
        mode: 'single',
        locale: Indonesian,
        altInput: true,
        altFormat: 'j/n/Y',
        dateFormat: 'Y-m-d',
        maxDate: today,
        disableMobile: true,
        plugins: [
          new MonthSelect({
            shorthand: true,
            dateFormat: 'Y-m-d',
            altFormat: 'M Y',
          }),
        ],
      },
      filterPerfomance: 'semua',
      filterPerfomanceOptions: [
        { value: 'semua', text: 'Semua' },
        { value: 'cod', text: 'COD' },
        { value: 'bank transfer', text: 'Non-COD' },
      ],
      options: {
        chart: {
          id: `vuechart-${Date.now()}`,
          toolbar: {
            show: false,
          },
          stacked: false,
        },
        fill: {
          colors: ['#47AEE4', '#FBA63C'],
        },
        colors: ['#47AEE4', '#FBA63C'],
        xaxis: {
          type: 'datetime',
          tickAmount: 14,
          categories: [],
          labels: {
            formatter: (val, timestamp) => this.$moment(new Date(timestamp)).format('DD'),
          },
        },
        yaxis: [
          {
            show: false,
            tickAmount: 14,
            opposite: true,
            seriesName: 'Jumlah Order',
          },
          {
            seriesName: 'Omset',
            tickAmount: 14,
            labels: {
              formatter: value => {
                let formatedVal = 0
                if (Math.abs(Number(value)) >= 1.0e9) {
                  formatedVal = `${Math.abs(Number(value)) / 1.0e9} Mn`
                } else if (Math.abs(Number(value)) >= 1.0e6) {
                  formatedVal = `${Math.abs(Number(value)) / 1.0e6} Jt`
                } else if (Math.abs(Number(value)) >= 1.0e3) {
                  formatedVal = `${Math.abs(Number(value)) / 1.0e3} Rb`
                } else {
                  formatedVal = Math.abs(Number(value))
                }
                return `${formatedVal}`
              },
            },
          },
        ],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        tooltip: {
          x: {
            show: true,
            format: 'DD MMMM YYYY',
            formatter: seriesName => this.$moment(new Date(seriesName)).format('DD MMMM YYYY'),
          },
          custom: ({
            series, seriesIndex, dataPointIndex, w,
          }) => {
            let htmlRender = ''
            const arrayData = [...w.globals.series]
            arrayData.forEach((x, idx) => {
              if (w.globals.collapsedSeriesIndices.indexOf(idx) !== -1) {
                htmlRender += ''
              } else {
                htmlRender += `
                <div class="d-flex align-items-center">
                  <div class="mr-1">
                    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="6" cy="6.5" r="6" fill="${w.globals.seriesNames[idx] === 'Omset' ? '#FBBC05' : '#47AEE4'}"/>
                    </svg>
                  </div>
                <div>
                <span>
                ${w.globals.seriesNames[idx] === 'Omset' ? this.formatRupiah(x[dataPointIndex] || 0) : x[dataPointIndex]}
                </span>
                <span>
                ${w.globals.seriesNames[idx] === 'Jumlah Order' ? 'Orderan' : ''}
                </span>
                </div>
                </div>
                `
              }
            })

            return `
            <div
              class="d-grid p-1 rounded align-items-center"
            >
              ${htmlRender}
              <br/>
            </div>
            `
          },
        },
      },
    }
  },
  mounted() {
    this.getRevenuePerformenceOrder()
  },
  methods: {
    formatRupiah(value) {
      let result = value
      if (value) result = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      return `Rp ${result}`
    },
    async getRevenuePerformenceOrder() {
      this.loading = true
      const params = {
        start_date: START_DATE_OF_MONTH(this.filterMonth),
        end_date: END_DATE_OF_MONTH(this.filterMonth),
      }
      if (this.filterPerfomance !== 'semua') Object.assign(params, { payment_method: this.filterPerfomance })
      this.$http_komship.get('/v1/dashboard/partner/revenueOrderPerformance', { params })
        .then(response => {
          const { data } = response.data
          if (data.data_days !== undefined) {
            this.options = {
              ...this.options,
              xaxis: {
                ...this.options.xaxis,
                categories: data.data_days.map(item => item.day),
              },
            }
            this.seriesRevenue = [
              {
                name: 'Jumlah Order',
                data: data.data_days.map(item => item.total_order),
              },
              {
                name: 'Omset',
                data: data.data_days.map(item => item.total_profit),
              },
            ]
            this.totalProfit = data.total_profit
            this.totalOrder = data.total_order
          } else this.seriesRevenue = []
          this.loading = false
        })
        .catch(error => {
          this.loading = false
        })
    },
    async shareRevenuePerformenceOrder() {
      this.loading = true
      const canvas = await html2canvas(document.getElementById('performence-revenue-order'))
      const image = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
      const a = document.createElement('a')
      const month = this.$moment().format('MMMM')
      a.setAttribute('download', `performa-omset-orderan-${month.toLowerCase()}.png`)
      a.setAttribute('href', image)
      a.click()
      this.loading = false
    },
  },
}
</script>
